import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/full/shared/loadable/Loadable';

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full/FullLayout')), { type: "sURL" });
const BlankLayout = Loadable(lazy(() => import('../layouts/blank/BlankLayout')));

/** Auth **/
const LoginPage = Loadable(lazy(() => import('../views/authentication/pages/LoginPage')))
const ForgetPasswordPage = Loadable(lazy(() => import('../views/authentication/pages/ForgotPasswordPage')))
const RegisterPage = Loadable(lazy(() => import('../views/authentication/pages/RegisterPage')))

/***** sURL ******/
const AnalyticsPageV1 = Loadable(lazy(() => import('../views/sURL/dashboard/v1')))
const AnalyticsPageV2 = Loadable(lazy(() => import('../views/sURL/dashboard/v2')))
const AudienceOverview = Loadable(lazy(() => import('src/views/sURL/audience/Overview')));
const LanguageView = Loadable(lazy(() => import('src/views/sURL/audience/LanguageView')));
const BrowsersView = Loadable(lazy(() => import('src/views/sURL/audience/BrowsersView')));
const OSView = Loadable(lazy(() => import('src/views/sURL/audience/OSView')));
const DevicesOverview = Loadable(lazy(() => import('src/views/sURL/audience/DevicesOverview')));
const MobileView = Loadable(lazy(() => import('src/views/sURL/audience/MobileView')));
const CountryView = Loadable(lazy(() => import('src/views/sURL/audience/geo/CountryView')));
const StateView = Loadable(lazy(() => import('src/views/sURL/audience/geo/StateView')));
const CityView = Loadable(lazy(() => import('src/views/sURL/audience/geo/CityView')));
const LocationView = Loadable(lazy(() => import('src/views/sURL/audience/geo/Location')));

const AcquisitionOverview = Loadable(lazy(() => import('src/views/sURL/acquisition')));
const SourceView = Loadable(lazy(() => import('src/views/sURL/acquisition/SourceView')));
const ChannelsView = Loadable(lazy(() => import('src/views/sURL/acquisition/ChannelsView')));
const TrafficView = Loadable(lazy(() => import('src/views/sURL/acquisition/TrafficView')));
const AllCampaignsView = Loadable(lazy(() => import('src/views/sURL/acquisition/campains/AllCampaignsView')));
const PaidCampaignsView = Loadable(lazy(() => import('src/views/sURL/acquisition/campains/PaidCampaginView')));
const OrganicCampaignsView = Loadable(lazy(() => import('src/views/sURL/acquisition/campains/OrganicKeywords')));
const NetworkReferralsView = Loadable(lazy(() => import('src/views/sURL/acquisition/social/NetworkReferrals')));
const SocialNetworkOverview = Loadable(lazy(() => import('src/views/sURL/acquisition/social/SocialOverview')));
const OrganicSearchView = Loadable(lazy(() => import('src/views/sURL/acquisition/search/OrganicSearchView')));
const PaidSearchView = Loadable(lazy(() => import('src/views/sURL/acquisition/search/OrganicSearchView')));
const BehaviorOverview = Loadable(lazy(() => import('src/views/sURL/behavior/index')));
const SitespeedView = Loadable(lazy(() => import('src/views/sURL/behavior/SitespeedView')));
const OutageView = Loadable(lazy(() => import('src/views/sURL/behavior/OutageView')));
const SiteAllView = Loadable(lazy(() => import('src/views/sURL/behavior/SiteAllView')));
const SiteLandingView = Loadable(lazy(() => import('src/views/sURL/behavior/SiteLandingView')));

/***** Settings ******/
const ProfileView = Loadable(lazy(() => import('src/views/settings/profile')));
const CompanyProfileView = Loadable(lazy(() => import('src/views/settings/companyprofile')));
const NotificationsView = Loadable(lazy(() => import('src/views/settings/notifications')));
const ThemeView = Loadable(lazy(() => import('src/views/settings/theme')));
const SecurityView = Loadable(lazy(() => import('src/views/settings/security')));
const AccountDefaultView = Loadable(lazy(() => import('src/views/settings/accountdefault/index')));
const TeamsView = Loadable(lazy(() => import('src/views/settings/teams')));
const UsersView = Loadable(lazy(() => import('src/views/settings/users')));
const RolesView = Loadable(lazy(() => import('src/views/settings/roles')));
const UsersLogView = Loadable(lazy(() => import('src/views/settings/users_log/index')));
const AccountHistoryView = Loadable(lazy(() => import('src/views/settings/account_history/index')));
const BillingOverview = Loadable(lazy(() => import('src/views/settings/billing')))
const InvoicesView = Loadable(lazy(() => import('src/views/settings/billing/InvoicesView')));
const PaymentHistoryView = Loadable(lazy(() => import('src/views/settings/billing/PaymentHistoryView')));
const APIKeysView = Loadable(lazy(() => import('src/views/settings/api_keys/index')));
const BulkImportView = Loadable(lazy(() => import('src/views/settings/import/BulkImportView')));
const ReportBugView = Loadable(lazy(() => import('src/views/settings/feedback/ReportBugView')));
const SuggestView = Loadable(lazy(() => import('src/views/settings/feedback/SuggestView')));

const Error = Loadable(lazy(() => import('../views/authentication/Error')));

const Router = [
  {
    path: '/',
    element: <BlankLayout />,
    children: [
      { path: '/', element: <Navigate to="/onboarding" /> },
      { path: '/onboarding', exact: true, element: <Navigate to="/auth" /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: '/auth',
    element: <BlankLayout />,
    children: [
      { path: '/auth', element: <Navigate to="/auth/login" /> },
      { path: '/auth/login', exact: true, element: <LoginPage /> },
      { path: '/auth/register', exact: true, element: <RegisterPage /> },
      { path: '/auth/forgot-password', exact: true, element: <ForgetPasswordPage /> },
      { path: '/auth/404', element: <Error /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: '/d',
    element: <FullLayout />,
    children: [
      { path: '/d', element: <Navigate to="/d/apps" /> },
      { path: '/d/apps', element: <FullLayout /> },
    ]
  },
  {
    path: '/d/sBox',
    element: <FullLayout />,
    children: [
      { path: '/d/sBox', element: <Navigate to="/d/apps" /> },
      { path: '/d/sBox/:sBoxID', element: <FullLayout /> },
    ]
  },
  {
    path: '/d/:sBoxID/sURL/:sURL',
    element: <FullLayout type={"sURL"} />,
    children: [
      //sURL Dashboard
      { path: '/d/:sBoxID/sURL/:sURL/v1', exact: true, element: <AnalyticsPageV1 /> },
      { path: '/d/:sBoxID/sURL/:sURL/v2', exact: true, element: <AnalyticsPageV2 /> },

      //Audience
      { path: '/d/:sBoxID/sURL/:sURL/audience_overview', exact: true, element: <AudienceOverview /> },
      //Geolocation
      { path: '/d/:sBoxID/sURL/:sURL/con', exact: true, element: <CountryView /> },
      { path: '/d/:sBoxID/sURL/:sURL/state', exact: true, element: <StateView /> },
      { path: '/d/:sBoxID/sURL/:sURL/city', exact: true, element: <CityView /> },
      { path: '/d/:sBoxID/sURL/:sURL/location', exact: true, element: <LocationView /> },
      //Language
      { path: '/d/:sBoxID/sURL/:sURL/language', exact: true, element: <LanguageView /> },
      //Browsers
      { path: '/d/:sBoxID/sURL/:sURL/browsers', exact: true, element: <BrowsersView /> },
      //Operating System
      { path: '/d/:sBoxID/sURL/:sURL/os', exact: true, element: <OSView /> },
      //Devices (Mobile and Others)
      { path: '/d/:sBoxID/sURL/:sURL/network', exact: true, element: <AnalyticsPageV1 /> },
      //Devices (Mobile and Others)
      { path: '/d/:sBoxID/sURL/:sURL/device/overview', exact: true, element: <DevicesOverview /> },
      { path: '/d/:sBoxID/sURL/:sURL/device/mobile', exact: true, element: <MobileView /> },

      // Acquisition
      // Overview
      { path: '/d/:sBoxID/sURL/:sURL/acquisition', exact: true, element: <AcquisitionOverview /> },
      { path: '/d/:sBoxID/sURL/:sURL/traffic', exact: true, element: <TrafficView /> },
      { path: '/d/:sBoxID/sURL/:sURL/channels', exact: true, element: <ChannelsView /> },
      { path: '/d/:sBoxID/sURL/:sURL/source', exact: true, element: <SourceView /> },
      { path: '/d/:sBoxID/sURL/:sURL/referrals', exact: true, element: <TrafficView /> },

      { path: '/d/:sBoxID/sURL/:sURL/ads/campaigns', exact: true, element: <AllCampaignsView /> },
      { path: '/d/:sBoxID/sURL/:sURL/ads/paid', exact: true, element: <PaidCampaignsView /> },
      { path: '/d/:sBoxID/sURL/:sURL/ads/organic', exact: true, element: <OrganicCampaignsView /> },
      // { path: '/d/:sBoxID/sURL/:sURL/ads/search', exact: true, element: <OrganicCampaignsView /> },
      // Social
      { path: '/d/:sBoxID/sURL/:sURL/social', exact: true, element: <SocialNetworkOverview /> },
      { path: '/d/:sBoxID/sURL/:sURL/social_network', exact: true, element: <NetworkReferralsView /> },
      // Campaigns
      { path: '/d/:sBoxID/sURL/:sURL/campaigns/paid_search', exact: true, element: <PaidSearchView /> },
      { path: '/d/:sBoxID/sURL/:sURL/campaigns/organic_search', exact: true, element: <OrganicSearchView /> },

      //BEHAVIOR
      { path: '/d/:sBoxID/sURL/:sURL/behavior/overview', exact: true, element: <BehaviorOverview /> },
      { path: '/d/:sBoxID/sURL/:sURL/behavior/speed', exact: true, element: <SitespeedView /> },
      { path: '/d/:sBoxID/sURL/:sURL/behavior/outage', exact: true, element: <OutageView /> },
      { path: '/d/:sBoxID/sURL/:sURL/behavior/site_all', exact: true, element: <SiteAllView /> },
      { path: '/d/:sBoxID/sURL/:sURL/behavior/site_landing', exact: true, element: <SiteLandingView /> },

      // Goals
      { path: '/d/:sBoxID/sURL/:sURL/goals/overview', exact: true, element: <AnalyticsPageV1 /> },
      { path: '/d/:sBoxID/sURL/:sURL/goals/add', exact: true, element: <AnalyticsPageV1 /> },
      { path: '/d/:sBoxID/sURL/:sURL/goals/kpi', exact: true, element: <AnalyticsPageV1 /> },

      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: '/settings',
    element: <FullLayout type={"settings"} />,
    children: [
      { path: '/settings', element: <Navigate to="/settings/profile" /> },
      { path: '/settings/profile', element: <ProfileView /> },
      { path: '/settings/company_profile', element: <CompanyProfileView /> },
      { path: '/settings/notifications', element: <NotificationsView /> },
      { path: '/settings/security', element: <SecurityView /> },  
      { path: '/settings/theme', element: <ThemeView /> },
      { path: '/settings/account_default', element: <AccountDefaultView /> },
      { path: '/settings/teams', element: <TeamsView /> },
      { path: '/settings/users', element: <UsersView /> },
      { path: '/settings/roles', element: <RolesView /> },
      { path: '/settings/account_history', element: <AccountHistoryView /> },
      { path: '/settings/users_log', element: <UsersLogView /> },
      { path: '/settings/billing_overview', element: <BillingOverview /> },
      { path: '/settings/invoices', element: <InvoicesView /> },
      { path: '/settings/payment_history', element: <PaymentHistoryView /> },
      { path: '/settings/api_keys', element: <APIKeysView /> },
      { path: '/settings/bulk_import', element: <BulkImportView /> },
      { path: '/settings/report_bug', element: <ReportBugView /> },
      { path: '/settings/suggest', element: <SuggestView /> },

      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: '/app/:id',
    element: <FullLayout />,
    children: [
      { path: '/app/:id', element: <AnalyticsPageV1 /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
];

export default Router;
